import React from 'react'
import {Link} from 'react-router-dom'

export default function Pager(props) {
  const {currentPage, totalItems} = props
  const pageCount = Math.ceil(totalItems / 20)
  if (pageCount < 2) {
    return null
  }

  const prevPage = Math.max(1, currentPage - 1)
  const nextPage = Math.min(pageCount, currentPage + 1)
  const pages = Array.from(Array(pageCount)).map((_, i) => 1 + i)

  return (
    <ul className='pagination mt-5 mt-xl-8'>
      <li className='page-item previous'>
        <Link to={`?page=${prevPage}`} className='page-link'>
          <i className='previous'></i>
        </Link>
      </li>
      {pages.map((page) => (
        <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
          <Link to={`?page=${page}`} className='page-link'>
            {page}
          </Link>
        </li>
      ))}
      <li className='page-item next'>
        <Link to={`?page=${nextPage}`} className='page-link'>
          <i className='next'></i>
        </Link>
      </li>
    </ul>
  )
}
