import axios from 'axios'
import {useEffect, useState} from 'react'
import {useAuth} from '../../modules/auth'

const AxiosErrorHandler = ({children}) => {
  const {logout} = useAuth()
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    axios.interceptors.response.use(
      function (response) {
        return response
      },
      function (error) {
        const {request, response, message} = error
        if (response) {
          if ([401, 403].includes(response.status)) {
            logout()
          }
        } else if (request) {
          console.log('request error', JSON.stringify(request))
        } else {
          console.log('generic error', message)
        }

        return Promise.reject(error)
      }
    )

    setInitialized(true)
  }, [])

  return initialized && children
}

export {AxiosErrorHandler}
