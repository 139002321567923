import axios from 'axios'
import {
  ResponseWrapper,
  UserModel,
  CountryModel,
  TransactionModel,
  TransactionStates,
  ResponseObjectWrapper,
} from './_models'
import {SettingsModel} from '../auth'

const API_URL = `${process.env.REACT_APP_API_URL}/firebase`

export function getDoc(collection: string, docId: string) {
  let docPath = `${API_URL}/doc?collection=${collection}&docId=${docId}`
  return axios.get(docPath)
}

export function getUser(email: string) {
  let path = `${API_URL}/users/${email}`
  return axios.get<UserModel>(path)
}

export function getUsers(page: number, startIndex?: string) {
  let path = `${API_URL}/users?page=${page}`
  if (startIndex) {
    path = `${path}&startIndex=${startIndex}`
  }
  return axios.get<ResponseWrapper<UserModel>>(path)
}

export function getCountries(page: number, startIndex?: string) {
  let path = `${API_URL}/countries?page=${page}`
  if (startIndex) {
    path = `${path}&startIndex=${startIndex}`
  }
  return axios.get<ResponseWrapper<CountryModel>>(path)
}

export function putCountry(docId: string, data: any) {
  let path = `${API_URL}/countries/${docId}`
  return axios.put<CountryModel>(path, data)
}

export function getTransactionStates() {
  let path = `${API_URL}/transfers/states`
  return axios.get<ResponseObjectWrapper<TransactionStates>>(path)
}

export function getTransactions(page: number, startIndex?: string) {
  let path = `${API_URL}/transfers?page=${page}`
  if (startIndex) {
    path = `${path}&startIndex=${startIndex}`
  }
  return axios.get<ResponseWrapper<TransactionModel>>(path)
}

export function putTransaction(docId: string, data: any) {
  let path = `${API_URL}/transfers/${docId}`
  return axios.put<TransactionModel>(path, data)
}

export function deleteTransaction(docId: string) {
  let path = `${API_URL}/transfers/${docId}`
  return axios.delete(path)
}

export function getConstants() {
  let path = `${API_URL}/constants`
  return axios.get<SettingsModel>(path)
}

export function putConstants(data: any) {
  let path = `${API_URL}/constants`
  return axios.put<SettingsModel>(path, data)
}
