import React from 'react'

export default function ActionMenu(props) {
  const {docId, onUpdate} = props
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-100px w-md-200px'
      data-kt-menu='true'
    >
      <div className='menu-item px-5'>
        <a
          href={`?uid=${docId}&enabled=true`}
          onClick={(e) => {
            e.preventDefault()
            onUpdate({docId, enabled: true})
          }}
          className='menu-link px-5'
        >
          Enable
        </a>
      </div>

      <div className='menu-item px-5'>
        <a
          href={`?uid=${docId}&enabled=false`}
          onClick={(e) => {
            e.preventDefault()
            onUpdate({docId, enabled: false})
          }}
          className='menu-link px-5'
        >
          Disable
        </a>
      </div>
    </div>
  )
}
