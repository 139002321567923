import React, {useEffect} from 'react'
import {Link, useSearchParams} from 'react-router-dom'
import {getTransactions, getTransactionStates, TransactionModel} from '../../modules/firebase'
import Pager from '../../../_metronic/layout/components/pager/Pager'
import {KTIcon, toFlagUrl} from '../../../_metronic/helpers'
import PageLoader from '../../../_metronic/layout/components/page-loader/PageLoader'
import moment from 'moment'
import {formatAmount} from './money'
import {useAppState} from '../../providers/state'
import {toBadgeStatus} from '../../../_metronic/helpers/DataHelpers'

export function TransactionsPage() {
  const {
    transactions: {state, setTransactions},
    transactionStates,
  } = useAppState()
  const [searchParams, setSearchParams] = useSearchParams()
  const currentPage = Number(searchParams.get('page')) || 1

  useEffect(() => {
    const fetchTransaction = async () => {
      try {
        if (state[currentPage.toString()]) {
          return
        }

        const prevPage = (currentPage - 1).toString()
        const prevIndex = state[prevPage]?.lastIndex
        const {data} = await getTransactions(currentPage, prevIndex)
        if (data) {
          setTransactions(currentPage.toString(), data)
        }
      } catch (error) {}
    }

    const fetchTransactionStates = async () => {
      if (transactionStates.state.admin.length > 0) {
        return
      }

      const {data} = await getTransactionStates()
      transactionStates.setTransactionStates(data)
    }

    fetchTransaction()
    fetchTransactionStates()
  }, [state, currentPage, setTransactions, transactionStates])

  const pageData = state[currentPage.toString()]
  if (!pageData) {
    return <PageLoader />
  }

  const renderRow = (tr: TransactionModel) => {
    const {sender, receiver, log = []} = tr
    const adminLog = transactionStates.getTransactionState('admin', log)

    return (
      <tr key={tr.docId}>
        <td>
          <Link to={tr.docId} state={{docId: tr.docId, pageKey: currentPage.toString()}}>
            <span className='text-dark fw-bold text-hover-primary fs-6'>{tr.referenceID}</span>
          </Link>
        </td>
        <td>
          <div className='symbol symbol-30px'>
            <img src={toFlagUrl(sender.country)} alt='' />
          </div>
        </td>
        <td>
          <span className='text-dark fw-bold d-block mb-1 fs-6'>{sender.country}</span>
          <span className='text-muted text-capitalize fw-semibold text-muted d-block fs-7'>
            {sender.name}
          </span>
        </td>
        <td>
          <div className='symbol symbol-30px'>
            <img src={toFlagUrl(receiver.country)} alt='' />
          </div>
        </td>
        <td>
          <span className='text-dark fw-bold d-block mb-1 fs-6'>{receiver.country}</span>
          <span className='text-muted text-capitalize fw-semibold text-muted d-block fs-7'>
            {receiver.name}
          </span>
        </td>
        <td>
          <span className='text-dark fw-bold d-block mb-1 fs-6'>
            {moment(tr.date._seconds ? tr.date._seconds * 1000 : tr.date).format('DD/MM/YYYY')}
          </span>
          <span className='text-muted text-center fw-semibold d-block fs-7'>
            {moment(tr.date._seconds ? tr.date._seconds * 1000 : tr.date).format('HH:mm')}
          </span>
        </td>
        <td>
          <span className='text-primary fw-bolder d-block mb-1 fs-5'>{formatAmount(sender)}</span>
        </td>
        <td className='text-dark fw-bold text-primary fs-6'>
          <span className='text-dark fw-bolder d-block mb-1 fs-5'>{formatAmount(tr.receiver)}</span>
        </td>
        <td>
          <span
            className={`badge badge-${toBadgeStatus(adminLog?.en.title ?? tr.status)} fw-bold fs-6`}
          >
            {adminLog?.en.title ?? tr.status}
          </span>
        </td>
        <td className='text-end'>
          <Link
            to={tr.docId}
            state={{docId: tr.docId, pageKey: currentPage.toString()}}
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          >
            <KTIcon iconName='pencil' className='fs-3' />
          </Link>
        </td>
      </tr>
    )
  }

  return (
    <div>
      <div className='card card-xl-stretch mt-5 mt-xl-8'>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Transactions</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>
              {`${pageData.totalDocs}`} Results
            </span>
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-100px'>Ref</th>
                  <th className='min-w-30px'></th>
                  <th className='min-w-120px'>From</th>
                  <th className='min-w-30px'></th>
                  <th className='min-w-120px'>To</th>
                  <th className='min-w-120px'>Date</th>
                  <th className='min-w-120px'>Sending</th>
                  <th className='min-w-120px'>Receiving</th>
                  <th className='min-w-120px'>Status</th>
                  <th className='min-w-100px text-end'>Action</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>{pageData.data.map((tr) => renderRow(tr))}</tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
      <Pager currentPage={currentPage} totalItems={pageData.totalDocs} />
    </div>
  )
}
