import React, {useEffect} from 'react'
import {getCountries, putCountry} from '../../modules/firebase'
import PageLoader from '../../../_metronic/layout/components/page-loader/PageLoader'
import Pager from '../../../_metronic/layout/components/pager/Pager'
import {useAppState} from '../../providers/state'
import ActionMenu from './ActionMenu'
import {useSearchParams} from 'react-router-dom'
import {MenuComponent} from '../../../_metronic/assets/ts/components'

export function CountriesPage() {
  const {
    countries: {state, setCountries, updateCountry},
  } = useAppState()
  const [searchParams, setSearchParams] = useSearchParams()
  const currentPage = Number(searchParams.get('page')) || 1

  useEffect(() => {
    const fetchCountries = async () => {
      if (state[currentPage.toString()]) {
        MenuComponent.reinitialization();
        return
      }

      const prevPage = (currentPage - 1).toString()
      const prevIndex = state[prevPage]?.lastIndex
      const {data} = await getCountries(currentPage, prevIndex)
      if (data) {
        setCountries(currentPage.toString(), data)
      }
    }

    fetchCountries()
  }, [state, currentPage, setCountries])

  const pageData = state[currentPage.toString()]
  if (!pageData) {
    return <PageLoader />
  }

  const onUpdateCountry = async ({docId, enabled}) => {
    const updated = await putCountry(docId, {enabled})
    updateCountry(currentPage.toString(), updated.data)
  }

  return (
    <div>
      <div className='card card-xl-stretch mt-5 mt-xl-8'>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Countries</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>
              {`${pageData.totalDocs}`} Results
            </span>
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-3'>
              {/* begin::Table head */}
              <thead>
                <tr>
                  <th className='p-0 w-50px'></th>
                  <th className='p-0 min-w-80px'></th>
                  <th className='p-0 min-w-80px'></th>
                  <th className='p-0 min-w-80px'></th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {pageData.data.map((country) => (
                  <tr key={country.docId}>
                    <td>
                      <div className='symbol symbol-30px me-3'>
                        <img src={country.flag} alt='' />
                      </div>
                    </td>
                    <td>
                      <span className='text-dark fw-bold mb-1 fs-6'>{country.name}</span>
                    </td>
                    <td className='text-end'>
                      <span className='text-dark fw-bold d-block fs-7'>{country.currency}</span>
                    </td>
                    <td className='text-end'>
                      <span
                        className={`badge badge-light-${
                          country.enabled ? 'success' : 'danger'
                        } fs-7 fw-bold`}
                      >
                        {country.enabled ? 'ENABLED' : 'DISABLED'}
                      </span>
                    </td>
                    <td className='text-end'>
                      <div>
                        <button
                          className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                          data-kt-menu-trigger="{default: 'click'}"
                          data-kt-menu-attach='parent'
                          data-kt-menu-placement='bottom-end'
                        >
                          <i className='bi bi-three-dots fs-5'></i>
                        </button>

                        <ActionMenu docId={country.docId} onUpdate={onUpdateCountry} />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
              {/* end::Table body */}
            </table>
          </div>
          {/* end::Table */}
        </div>
        {/* end::Body */}
      </div>

      <Pager currentPage={currentPage} totalItems={pageData.totalDocs} />
    </div>
  )
}
