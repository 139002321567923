/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItemWithSub
        to='/firebase'
        title='Firebase'
        fontIcon='bi-fire'
        icon='abstract-18'
      >
        <SidebarMenuItem to='/firebase/transactions' title='Transactions' hasBullet={true} />
        <SidebarMenuItem to='/firebase/users' title='Users' hasBullet={true} />
        <SidebarMenuItem to='/firebase/countries' title='Countries' hasBullet={true} />
        <SidebarMenuItem to='/firebase/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub>
      {/* <SidebarMenuItemWithSub
        to='/mongo'
        title='MongoDb'
        fontIcon='bi-archive'
        icon='element-plus'
      >
        <SidebarMenuItem to='/mongo/transactions' title='Transactions' hasBullet={true} />
        <SidebarMenuItem to='/mongo/users' title='Users' hasBullet={true} />
        <SidebarMenuItem to='/mongo/countries' title='Countries' hasBullet={true} />
        <SidebarMenuItem to='/mongo/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
    </>
  )
}

export {SidebarMenuMain}
