import React, {useEffect} from 'react'
import {getUsers} from '../../modules/firebase'
import {KTIcon, toFlagUrl} from '../../../_metronic/helpers'
import moment from 'moment'
import PageLoader from '../../../_metronic/layout/components/page-loader/PageLoader'
import Pager from '../../../_metronic/layout/components/pager/Pager'
import {useAppState} from '../../providers/state'
import {useSearchParams} from 'react-router-dom'

export function UsersPage() {
  const {
    users: {state, setUsers},
  } = useAppState()
  const [searchParams, setSearchParams] = useSearchParams()
  const currentPage = Number(searchParams.get('page')) || 1

  useEffect(() => {
    const fetchUsers = async () => {
      if (state[currentPage.toString()]) {
        return
      }

      const prevPage = (currentPage - 1).toString()
      const prevIndex = state[prevPage]?.lastIndex
      const {data} = await getUsers(currentPage, prevIndex)
      if (data) {
        setUsers(currentPage.toString(), data)
      }
    }

    fetchUsers()
  }, [state, currentPage, setUsers])

  const pageData = state[currentPage.toString()]
  if (!pageData) {
    return <PageLoader />
  }

  return (
    <div>
      <div className='card card-xl-stretch mt-5 mt-xl-5'>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Firebase Users</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>{`${pageData.totalDocs} Results`}</span>
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-120px'></th>
                  <th className='min-w-80px'>Phone</th>
                  <th className='min-w-80px'>E-mail</th>
                  <th className='min-w-80px'>
                    E-mail <br />
                    Verified
                  </th>
                  <th className='min-w-80px'>Disabled</th>
                  <th className='min-w-80px'>
                    Identity <br />
                    Verified
                  </th>
                  <th className='min-w-80px'>Device</th>
                  <th className='min-w-80px'>Joined</th>
                  <th className='min-w-80px'>Last seen</th>
                  <th className='min-w-100px text-end'>Action</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {pageData.data.map((user) => {
                  return (
                    <tr key={user.uid}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='symbol symbol-35px me-5'>
                            <img src={toFlagUrl(user.country)} alt='' />
                          </div>
                          <div className='d-flex justify-content-start flex-column'>
                            <span className='text-dark text-capitalize fw-bold fs-6'>
                              {user.fullname}
                            </span>
                            <span className='text-muted text-uppercase fw-semibold text-muted d-block fs-7'>
                              {user.country}
                              <span className='fw-bold'>{` [${user.lang}]`}</span>
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                          {user.phone}
                        </span>
                      </td>

                      <td>
                        <a
                          href={`mailto:${user.email}`}
                          target='_top'
                          className='text-dark fw-bold text-hover-primary d-block fs-6'
                        >
                          {user.email}
                        </a>
                      </td>

                      <td>
                        <span
                          className={`badge badge-light-${
                            user.emailVerified ? 'success' : 'danger'
                          } fw-bold d-block fs-6`}
                        >
                          {String(user.emailVerified)}
                        </span>
                      </td>

                      <td>
                        <span className='text-dark fw-bold text-primary d-block fs-6'>
                          {String(user.disabled)}
                        </span>
                      </td>

                      <td>
                        <span
                          className={`badge badge-light-${
                            user.identityVerified ? 'success' : 'danger'
                          } fw-bold d-block fs-6`}
                        >
                          {String(user.identityVerified ?? false)}
                        </span>
                      </td>

                      <td>
                        <span className='text-dark fw-bold text-primary d-block fs-6'>
                          {user.device.meta.android ? 'android' : 'ios'}
                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                            v:{user.device.appVersion}
                          </span>
                        </span>
                      </td>

                      <td>
                        <span className='text-dark fw-bold text-primary d-block fs-6'>
                          {moment(
                            user.date._seconds ? user.date._seconds * 1000 : user.date
                          ).format('DD/MM/YYYY')}
                        </span>
                      </td>

                      <td>
                        <span className='text-dark fw-bold text-primary d-block fs-6'>
                          {moment(
                            user.device.lastSeen._seconds
                              ? user.device.lastSeen._seconds * 1000
                              : user.device.lastSeen
                          ).fromNow()}
                        </span>
                      </td>

                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <button className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                            <KTIcon iconName='pencil' className='fs-3' />
                          </button>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>

      <Pager currentPage={currentPage} totalItems={pageData.totalDocs} />
    </div>
  )
}
