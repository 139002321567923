import {
  UserAction,
  CountryAction,
  TransactionAction,
  AppStateContextProps,
  TransactionStatesAction,
} from './_actions'

export function reducer(
  state: AppStateContextProps,
  action: UserAction | CountryAction | TransactionAction | TransactionStatesAction
) {
  const {type, payload} = action
  switch (type) {
    case 'SET_USERS': {
      const {key, data} = payload
      return {
        ...state,
        users: {
          ...state.users,
          [key]: data,
        },
      }
    }
    case 'UPDATE_USER': {
      const users = state.users
      const {key, data} = payload
      const page = users[key]
      const pageData = page.data.map((user) => {
        if (user.docId === data.docId) {
          return data
        } else {
          return user
        }
      })
      return {
        ...state,
        users: {
          ...users,
          [key]: {
            ...page,
            data: pageData,
          },
        },
      }
    }

    case 'SET_COUNTRIES': {
      const {key, data} = payload
      return {
        ...state,
        countries: {
          ...state.countries,
          [key]: data,
        },
      }
    }
    case 'UPDATE_COUNTRY': {
      const countries = state.countries
      const {key, data} = payload
      const page = countries[key]
      const pageData = page.data.map((country) => {
        if (country.docId === data.docId) {
          return data
        } else {
          return country
        }
      })
      return {
        ...state,
        countries: {
          ...countries,
          [key]: {
            ...page,
            data: pageData,
          },
        },
      }
    }

    case 'SET_TRANS': {
      const {key, data} = payload
      return {
        ...state,
        transactions: {
          ...state.transactions,
          [key]: data,
        },
      }
    }
    case 'UPDATE_TRANS': {
      const transactions = state.transactions
      const {key, data} = payload
      const page = transactions[key]
      const pageData = page.data.map((trans) => {
        if (trans.docId === data.docId) {
          return data
        } else {
          return trans
        }
      })
      return {
        ...state,
        transactions: {
          ...transactions,
          [key]: {
            ...page,
            data: pageData,
          },
        },
      }
    }
    case 'DELETE_TRANS': {
      let transactions = state.transactions
      const {key, docId} = payload
      const page = transactions[key]
      const pageData = page.data.filter((trans) => trans.docId !== docId)

      if (pageData.length > 0) {
        return {
          ...state,
          transactions: {
            ...transactions,
            [key]: {
              ...page,
              data: pageData,
            },
          },
        }
      } else {
        delete transactions[key]
        return {
          ...state,
          transactions,
        }
      }
    }

    case 'SET_TRANS_STATES': {
      const {data} = payload
      return {
        ...state,
        transactionStates: data,
      }
    }
  }
}
