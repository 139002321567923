import React, {createContext} from 'react'
import {reducer} from './_reducers'
import {AppStateContextProps, AppDispatch} from './_actions'
import {
  CountryModel,
  TransactionModel,
  UserModel,
  ResponseWrapper,
  TransactionState,
  TransactionStates,
  ResponseObjectWrapper,
  TransactionLog,
} from '../../modules/firebase'

const initAppStateContextProps: AppStateContextProps = {
  users: {},
  countries: {},
  transactions: {},
  transactionStates: {
    admin: [],
    sender: [],
    receiver: [],
  },
}

const AppContext = createContext<[AppStateContextProps, AppDispatch]>([
  initAppStateContextProps,
  (action) => {},
])

function AppStateProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, initAppStateContextProps)

  const value = React.useMemo(() => [state, dispatch], [state])
  return <AppContext.Provider value={value} {...props} />
}

function useAppState() {
  const context = React.useContext(AppContext)
  if (!context) {
    throw new Error(`useAppState must be used within an AppStateProvider`)
  }
  const [{users, countries, transactions, transactionStates}, dispatch] = context

  // get
  const getUser = (key: string, docId: string) => {
    return users[key]?.data?.find((user) => user.docId === docId)
  }

  const getCountry = (key: string, docId: string) => {
    return countries[key]?.data?.find((country) => country.docId === docId)
  }

  const getTransaction = (key: string, docId: string) => {
    return transactions[key]?.data?.find((trans) => trans.docId === docId)
  }

  const getTransactionState = (type: string, log: TransactionLog[]) => {
    if (!transactionStates) {
      return null
    }

    console.log("xxxxx getTransactionState", type, transactionStates);
    

    const last = log.findLast((l) => l.type === type)
    const states = transactionStates[type] as TransactionState[]
    return states.find((s) => s.id === last?.id ?? 1)
  }

  // set
  const setUsers = (key: string, data: ResponseWrapper<UserModel>) =>
    dispatch({type: 'SET_USERS', payload: {key, data}})

  const setCountries = (key: string, data: ResponseWrapper<CountryModel>) =>
    dispatch({type: 'SET_COUNTRIES', payload: {key, data}})

  const setTransactions = (key: string, data: ResponseWrapper<TransactionModel>) =>
    dispatch({type: 'SET_TRANS', payload: {key, data}})

  const setTransactionStates = (data: ResponseObjectWrapper<TransactionStates>) =>
    dispatch({type: 'SET_TRANS_STATES', payload: data})

  // update
  const updateUser = (key: string, data: UserModel) =>
    dispatch({type: 'UPDATE_USER', payload: {key, data}})

  const updateCountry = (key: string, data: CountryModel) =>
    dispatch({type: 'UPDATE_COUNTRY', payload: {key, data}})

  const updateTransaction = (key: string, data: TransactionModel) =>
    dispatch({type: 'UPDATE_TRANS', payload: {key, data}})

  const deleteTransaction = (key: string, docId: string) =>
    dispatch({type: 'DELETE_TRANS', payload: {key, docId}})

  return {
    users: {state: users, getUser, setUsers, updateUser},
    countries: {state: countries, getCountry, setCountries, updateCountry},
    transactions: {
      state: transactions,
      getTransaction,
      setTransactions,
      updateTransaction,
      deleteTransaction,
    },
    transactionStates: {
      state: transactionStates,
      setTransactionStates,
      getTransactionState,
    },
  }
}

export {AppStateProvider, useAppState}
