import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {CountriesPage} from '../pages/countries'
import {SettingsPage} from '../pages/settings'
import {TransactionsPage, TransactionDetailPage} from '../pages/transanctions'
import {UsersPage} from '../pages/users'

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registration */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='/firebase'>
          <Route path='countries' element={<CountriesPage />} />
          <Route path='settings' element={<SettingsPage />} />
          <Route path='transactions' element={<TransactionsPage />} />
          <Route path='transactions/:docId' element={<TransactionDetailPage />} />
          <Route path='users' element={<UsersPage />} />
        </Route>
        <Route path='dashboard' element={<DashboardWrapper />} />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export {PrivateRoutes}
