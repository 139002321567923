import React, {useState, useEffect} from 'react'
import {
  getDoc,
  getUser,
  deleteTransaction,
  putTransaction,
  TransactionModel,
  UserModel,
  getTransactionStates,
} from '../../modules/firebase'
import PageLoader from '../../../_metronic/layout/components/page-loader/PageLoader'
import moment from 'moment'

import {formatAmount, formatMoney} from './money'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {useAppState} from '../../providers/state'
import {KTIcon, KTSVG, toFlagUrl} from '../../../_metronic/helpers'
import {ActionMenu} from './ActionMenu'
import {toBadgeStatus} from '../../../_metronic/helpers/DataHelpers'

export function TransactionDetailPage() {
  const location = useLocation()
  const navigate = useNavigate()
  const {pageKey, docId} = location.state as {pageKey: string; docId: string}
  const {transactions, transactionStates} = useAppState()
  const [transaction, setTransaction] = useState<TransactionModel | undefined>(
    transactions.getTransaction(pageKey, docId)
  )
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [senderInfo, setSenderInfo] = useState<UserModel | undefined>()

  useEffect(() => {
    const fetchTransaction = async () => {
      const doc = await getDoc('transactions', docId)
      setTransaction(doc.data)
    }

    const fetchUser = async (email) => {
      const user = await getUser(email)
      setSenderInfo(user.data)
    }

    const fetchTransactionStates = async () => {
      const {data} = await getTransactionStates()
      transactionStates.setTransactionStates(data)
    }

    if (transactionStates.state.admin.length === 0) {
      fetchTransactionStates()
    }

    if (!transaction) {
      fetchTransaction()
    }

    if (transaction && !senderInfo) {
      fetchUser(transaction.sender.email)
    }
  }, [transaction, senderInfo, docId])

  const deleteTrans = async () => {
    await deleteTransaction(docId)
    transactions.deleteTransaction(pageKey, docId)
    navigate('../transactions', {state: {page: Number(pageKey)}})
  }

  const updateTransaction = async (selectedId: number) => {
    const log = transaction?.log ?? []
    const updated = {
      ...transaction,
      log: [
        ...log,
        {
          date: new Date().getTime(),
          id: selectedId,
          type: 'admin',
        },
      ],
    }

    setTransaction(updated as TransactionModel)
  }

  const saveTransactionChanges = async () => {
    if (!transaction) {
      return
    }

    setSubmitting(true)
    await putTransaction(docId, transaction)
    transactions.updateTransaction(pageKey, transaction)
    setSubmitting(false)
  }

  const tr = transaction
  if (!tr) {
    return <PageLoader />
  }

  const {sender, receiver, totals, rates, log = []} = tr
  const adminLog = transactionStates.getTransactionState('admin', log)

  return (
    <div className=''>
      {/* begin::Body */}
      <div className='app-content flex-column-fluid'>
        {/* begin::Content container */}
        <div id='kt_app_content_container' className='app-container container-xxl'>
          {/* begin::Order details page */}
          <div className='d-flex flex-column gap-7 gap-lg-10'>
            <div className='d-flex flex-wrap flex-stack gap-5 gap-lg-10'>
              {/* begin:::Tabs */}
              <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-lg-n2 me-auto'>
                <h2>Transfer #{tr?.referenceID}</h2>
              </ul>
              {/* end:::Tabs */}
              {/* begin::Button */}
              <Link
                to='../transactions'
                state={{page: Number(pageKey)}}
                className='btn btn-secondary btn-sm me-lg-n7'
              >
                Back
              </Link>
              {/* end::Button */}
              {/* begin::Button */}
              <button
                className='btn btn-success btn-sm'
                onClick={saveTransactionChanges}
                data-kt-indicator={submitting ? 'on' : 'off'}
              >
                <span className='indicator-label'>Save Changes</span>
                <span className='indicator-progress'>
                  Saving Changes...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              </button>
              {/* end::Button */}
            </div>
            {/* begin::Order summary */}
            <div className='d-flex flex-column flex-xl-row gap-7 gap-lg-10'>
              {/* begin::Customer details */}
              <div className='card card-flush py-4 flex-row-fluid'>
                {/* begin::Card header */}
                <div className='card-header'>
                  <div className='card-title'>
                    <h2>Sender Details</h2>
                  </div>
                </div>
                {/* end::Card header */}
                {/* begin::Card body */}
                <div className='card-body pt-0'>
                  <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px'>
                      <tbody className='fw-semibold text-gray-600'>
                        <tr>
                          <td className='text-muted'>
                            <div className='d-flex align-items-center'>
                              <i className='ki-duotone ki-profile-circle fs-2 me-2'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                                <span className='path3'></span>
                              </i>
                              Name
                            </div>
                          </td>
                          <td className='fw-bold text-end'>
                            <div className='d-flex align-items-center justify-content-end'>
                              {/* begin::Name */}
                              <span className='text-gray-600 text-hover-primary'>
                                {sender.name}
                              </span>
                              {/* end::Name */}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className='text-muted'>
                            <div className='d-flex align-items-center'>
                              <i className='ki-duotone ki-map fs-2 me-2'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                              </i>
                              Country
                            </div>
                          </td>
                          <td className='fw-bold text-end'>
                            <div className='symbol symbol-30px me-3'>
                              <img src={toFlagUrl(sender.country)} alt='' />
                            </div>
                            <span>{sender.country}</span>
                          </td>
                        </tr>
                        <tr>
                          <td className='text-muted'>
                            <div className='d-flex align-items-center'>
                              <i className='ki-duotone ki-sms fs-2 me-2'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                              </i>
                              Email
                            </div>
                          </td>
                          <td className='fw-bold text-end'>
                            <a
                              href={`mailto:${sender.email}?subject=Transfer #${tr.referenceID}`}
                              className='text-gray-600 text-hover-primary'
                            >
                              {sender.email}
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className='text-muted'>
                            <div className='d-flex align-items-center'>
                              <i className='ki-duotone ki-phone fs-2 me-2'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                              </i>
                              Phone
                            </div>
                          </td>
                          <td className='fw-bold text-end'> {senderInfo?.phone} </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* end::Table */}
                  </div>
                </div>
                {/* end::Card body */}
              </div>
              {/* end::Customer details */}
              {/* begin::Documents */}
              <div className='card card-flush py-4 flex-row-fluid'>
                {/* begin::Card header */}
                <div className='card-header'>
                  <div className='card-title'>
                    <h2>Receiver Details</h2>
                  </div>
                </div>
                {/* end::Card header */}
                {/* begin::Card body */}
                <div className='card-body pt-0'>
                  <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px'>
                      <tbody className='fw-semibold text-gray-600'>
                        <tr>
                          <td className='text-muted'>
                            <div className='d-flex align-items-center'>
                              <i className='ki-duotone ki-profile-circle fs-2 me-2'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                                <span className='path3'></span>
                              </i>
                              Name
                            </div>
                          </td>
                          <td className='fw-bold text-end'>
                            <div className='d-flex align-items-center justify-content-end'>
                              {/* begin::Name */}
                              <span className='text-gray-600 text-hover-primary'>
                                {receiver.name}
                              </span>
                              {/* end::Name */}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className='text-muted'>
                            <div className='d-flex align-items-center'>
                              <i className='ki-duotone ki-map fs-2 me-2'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                              </i>
                              Country
                            </div>
                          </td>
                          <td className='fw-bold text-end'>
                            <div className='symbol symbol-30px me-3'>
                              <img src={toFlagUrl(receiver.country)} alt='' />
                            </div>
                            <span>{receiver.country}</span>
                          </td>
                        </tr>
                        <tr>
                          <td className='text-muted'>
                            <div className='d-flex align-items-center'>
                              <i className='ki-duotone ki-sms fs-2 me-2'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                              </i>
                              Email
                            </div>
                          </td>
                          <td className='fw-bold text-end'>
                            <a
                              href={`mailto:${receiver.email}?subject=Transfer #${tr.referenceID}`}
                              className='text-gray-600 text-hover-primary'
                            >
                              {receiver.email}
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className='text-muted'>
                            <div className='d-flex align-items-center'>
                              <i className='ki-duotone ki-phone fs-2 me-2'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                              </i>
                              Phone
                            </div>
                          </td>
                          <td className='fw-bold text-end'>{receiver.phone}</td>
                        </tr>
                      </tbody>
                    </table>
                    {/* end::Table */}
                  </div>
                </div>
                {/* end::Card body */}
              </div>
              {/* end::Documents */}
            </div>
            {/* end::Order summary */}
            {/* begin::Tab content */}
            <div className='tab-content'>
              {/* begin::Tab pane */}
              <div className='tab-pane fade show active'>
                {/* begin::Orders */}
                <div className='d-flex flex-column gap-7 gap-lg-10'>
                  {/* begin::Product List */}
                  <div className='card card-flush py-4 flex-row-fluid overflow-hidden'>
                    {/* begin::Card header */}
                    <div className='card-header'>
                      <div className='card-title'>
                        <h2>Transfer Details</h2>
                      </div>
                      <div className='card-toolbar m-0'>
                        <button
                          type='button'
                          className='btn btn-clean btn-sm btn-icon btn-icon-primary btn-active-light-primary me-n3'
                          data-kt-menu-trigger="{default: 'click'}"
                          data-kt-menu-attach='parent'
                          data-kt-menu-placement='bottom-end'
                        >
                          <KTIcon iconName='category' className='fs-3 text-primary' />
                        </button>

                        <ActionMenu
                          onApply={updateTransaction}
                          options={transactionStates.state}
                          selected={tr.log ? tr.log[tr.log.length - 1] : undefined}
                        />
                      </div>
                    </div>
                    {/* end::Card header */}
                    {/* begin::Card body */}
                    <div className='card-body pt-0'>
                      <div className='table-responsive'>
                        {/* begin::Table */}
                        <table className='table align-middle table-row-dashed fs-6 gy-5 mb-0'>
                          <thead>
                            <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                              <th className='min-w-80px'>Date</th>
                              <th className='min-w-80px text-center'>Status</th>
                              <th className='min-w-100px text-center'>Send Amount</th>
                              <th className='min-w-70px text-center'>Market Rate</th>
                              <th className='min-w-100px text-center'>Our Rate</th>
                              <th className='min-w-100px text-center'>Rate Comm.</th>
                              <th className='min-w-100px text-center'>Send Comm.</th>
                              <th className='min-w-100px text-end'>Receive Amount</th>
                            </tr>
                          </thead>
                          <tbody className='fw-semibold text-gray-600'>
                            <tr>
                              <td>
                                <div className='d-flex align-items-center'>
                                  <div className='fs-6 text-muted fw-bolder'>
                                    {moment(tr?.date._seconds ? tr.date._seconds * 1000 : tr.date).format('DD/MM/YYYY HH:mm')}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <span
                                  className={`badge badge-${toBadgeStatus(
                                    adminLog?.en.title ?? 'Paused'
                                  )} fw-bold d-block fs-6`}
                                >
                                  {adminLog?.en.title}
                                </span>
                              </td>
                              <td className='text-center fw-bolder text-success'>
                                {formatAmount(sender)}
                              </td>
                              <td className='text-center'>{formatMoney(rates.marketRate)}</td>
                              <td className='text-center fw-bolder text-info'>
                                {formatMoney(rates.ourRate)}
                              </td>
                              <td className='text-center'>{rates.rateCommission}</td>
                              <td className='text-center'>{rates.sendCommission}</td>
                              <td className='text-end fw-bolder text-primary'>
                                {formatAmount(receiver)}
                              </td>
                            </tr>
                            <tr>
                              <td className='text-end' colSpan={6}>
                                Subtotal
                              </td>
                              <td className='text-end' colSpan={6}>
                                {formatAmount(sender)}
                              </td>
                            </tr>
                            <tr>
                              <td className='text-end' colSpan={6}>
                                Transfer fee
                              </td>
                              <td className='text-end' colSpan={6}>
                                {formatMoney(totals.sendFees) ?? 0} {sender.currency}
                              </td>
                            </tr>
                            <tr>
                              <td className='text-end' colSpan={6}>
                                Rate commission
                              </td>
                              <td className='text-end' colSpan={6}>
                                {formatMoney(totals.rateFees) ?? 0} {sender.currency}
                              </td>
                            </tr>
                            <tr>
                              <td className='fs-3 text-dark text-end' colSpan={6}>
                                Grand Total
                              </td>
                              <td className='text-dark fs-3 fw-bolder text-end' colSpan={6}>
                                {formatMoney(totals.totalSend)} {sender.currency}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        {/* end::Table */}
                      </div>
                    </div>
                    {/* end::Card body */}
                  </div>
                  {/* end::Product List */}
                </div>
                {/* end::Orders */}
              </div>
              {/* end::Tab pane */}
            </div>
            {/* end::Tab content */}
            <button
              className='btn btn-danger btn-md d-none'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_delete'
            >
              DELETE TRANSFER
            </button>
            <div className='modal fade' tabIndex={-1} id='kt_modal_delete' role='dialog'>
              <div className='modal-dialog'>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h5 className='modal-title fw-bold fs-4'>Delete Transfer</h5>
                    <div
                      className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr061.svg'
                        className='svg-icon svg-icon-2x'
                      />
                    </div>
                  </div>
                  <div className='modal-body fw-bold fs-4'>
                    <p>
                      Are you sure you want to delete transfer #{tr.referenceID}? <br />
                      This operation is unrecoverable.
                    </p>
                  </div>
                  <div className='modal-footer'>
                    <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                      Close
                    </button>
                    <button
                      type='button'
                      className='btn btn-danger'
                      data-bs-dismiss='modal'
                      onClick={deleteTrans}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end::Order details page */}
        </div>
        {/* end::Content container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}
