export const toBadgeStatus = (status: string) => {
  switch (status) {
    case 'Awaiting your payment':
      return 'light'

    case 'Paid':
    case 'Sent':
    case 'Collected':
      return 'light-success'

    case 'Paused':
      return 'light-warning'

    case 'Cancelled':
        return 'light-danger'

    case 'Processing':
      return 'light-info'

    default:
      return 'light-primary'
  }
}
