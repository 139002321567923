import {Sender, Receiver} from '../../modules/firebase'

export function formatMoney(value: any) {
  if (!value) {
    return
  }

  return value.toLocaleString(undefined, {
    maximumFractionDigits: 2,
  })
}

export function formatAmount(item: Sender | Receiver) {
  const amount = formatMoney(item.amount)
  const currency = item.currency

  return `${amount} ${currency.toUpperCase()}`
}
