import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {SettingsModel} from '../../modules/auth'
import {getConstants, putConstants} from '../../modules/firebase'

const settingsSchema = Yup.object().shape({
  rateCommission: Yup.number().min(0).max(100),
  sendCommission: Yup.number().min(0).max(100),
})

const initialValues: SettingsModel = {
  rateCommission: 0,
  sendCommission: 0,
}

export function SettingsPage() {
  const [data, setData] = useState<SettingsModel>(initialValues)
  const [loading, setLoading] = useState(false)

  const formik = useFormik<SettingsModel>({
    initialValues: data,
    validationSchema: settingsSchema,
    onSubmit: async (values) => {
      setLoading(true)

      values.rateCommission = Number(values.rateCommission)
      values.sendCommission = Number(values.sendCommission)

      const merged = Object.assign(data, values)
      const updated = await putConstants(merged)

      setData(updated.data)
      setLoading(false)
    },
  })

  useEffect(() => {
    const fetchConstants = async () => {
      const {data} = await getConstants()
      if (data) {
        formik.setValues(data)
      }
    }

    fetchConstants()
  }, [])

  return (
    <div>
      <div className='card card-xl-stretch mt-5 mt-xl-5'>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Settings</span>
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin:: container */}
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                Rate Commission (%)
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='% Rate'
                  {...formik.getFieldProps('rateCommission')}
                />
                {formik.touched.rateCommission && formik.errors.rateCommission && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.rateCommission}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Send Commission (%)</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='% Rate'
                  {...formik.getFieldProps('sendCommission')}
                />
                {formik.touched.sendCommission && formik.errors.sendCommission && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.sendCommission}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Save Changes'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
          {/* end:: container */}
        </div>
        {/* begin::Body */}
      </div>
    </div>
  )
}
